import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { IonicVue } from "@ionic/vue";
import VueCookies from "vue-cookies";
import VueLoading from "vue-loading-overlay";
import VueAwesomeSwiper from "vue-awesome-swiper";
import LottieAnimation from "lottie-web-vue";
import VueScreen from "vue-screen";
import SocketIO from "socket.io-client";
import VueSocketIOExt from "vue-socket.io-extended"; // Sementara yg bermaslah di socket io utk vuejs

import Swiper, {
  Autoplay,
  Navigation,
  EffectFade,
  EffectCards,
  EffectCube,
  EffectCreative,
  EffectCoverflow,
  EffectFlip,
  Pagination,
} from "swiper";

Swiper.use([
  Autoplay,
  Navigation,
  EffectFade,
  EffectCards,
  EffectCube,
  EffectCreative,
  EffectCoverflow,
  EffectFlip,
  Pagination,
]);

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/tailwindcss.css";
import "@vuepic/vue-datepicker/dist/main.css";
import "vue-loading-overlay/dist/vue-loading.css";
import "swiper/css/bundle";
import "swiper/css";
import "@ionic/vue/css/ionic-swiper.css";

// const socket = SocketIO("https://industry.socket.anugerah.me"); //FOR Industry
const socket = SocketIO("https://socket.intranet.anugerah.me"); //FOR Anugerah APP

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(VueCookies)
  .use(VueLoading, {
    loader: "bars",
    color: "#72FFFF",
    backgroundColor: "#CDF0EA",
    width: 50,
    height: 50,
    opacity: 0.3,
  })
  .use(VueAwesomeSwiper)
  .use(LottieAnimation)
  .use(VueScreen)
  .use(VueSocketIOExt, socket);

router.isReady().then(() => {
  app.mount("#app");
});
