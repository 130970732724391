
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonPage,
  IonButton,
  IonInput,
  toastController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "HomePage",
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonPage,
    IonButton,
    IonInput,
  },
  data() {
    return {
      invoiceProduct: "",
      invoiceDetail: "",
      id: "",
      pass: "",
      selectedFilter: {
        mode: "",
        date: "",
      },
      invoiceFinal: {
        invID: "",
        invIssuer: "",
        invProduct: "",
        invDetail: "",
        fixInvID: this.$route.query.invoiceId,
      },
      fixInvoiceID: this.$route.query.invoiceId,
      invoiceID: this.$route.query.invoiceId as string,
      render: false,
      userData: JSON.parse(localStorage.getItem("userData") || "{}"),
    };
  },
  async mounted() {
    let loader = this.$loading.show({
      // Optional parameters
      // container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: false,
    });
    await axios
      .get("https://api.anugerah.me/invoice/getinvoice/" + this.invoiceID)
      .then((response) => {
        // console.log(response.data);
        // this.invoiceList = response.data;
        // console.log(this.invoiceList.length);
        this.invoiceDetail = response.data[0].invoiceDetail;
        this.invoiceProduct = response.data[0].invoiceProduct;
        // console.log(this.invoiceProduct);
      })
      .catch((error) => {
        console.log(error);
      });
    loader.hide();
    this.render = true;
  },
  methods: {
    BacktoDashboardPage() {
      this.$router.replace("/invoice");
    },
    async invApproval() {
      this.invoiceFinal.invProduct = this.invoiceProduct;
      this.invoiceFinal.invDetail = this.invoiceDetail;
      this.invoiceFinal.invIssuer = this.userData.user;
      this.invoiceFinal.invID = this.invoiceID;
      //   console.log(this.invoiceFinal);
      let loader = this.$loading.show({
        // Optional parameters
        // container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
      });
      await axios
        .post(
          "https://api.anugerah.me/invoice/invoiceapproval",
          this.invoiceFinal
        )
        .then((response) => {
          console.log(response.data);
          this.invoiceToast(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      loader.hide();
      this.$router.replace("/home/dashboardtab");
    },
    async invDecline() {
      this.invoiceFinal.invProduct = this.invoiceProduct;
      this.invoiceFinal.invDetail = this.invoiceDetail;
      this.invoiceFinal.invIssuer = this.userData.user;
      console.log(this.invoiceFinal);
      let loader = this.$loading.show({
        // Optional parameters
        // container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
      });
      await axios
        .post(
          "https://api.anugerah.me/invoice/invoicedecline",
          this.invoiceFinal
        )
        .then((response) => {
          console.log(response.data);
          this.invoiceToast(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      loader.hide();
      this.$router.replace("/home/dashboardtab");
    },
    invoiceEditPage(invoiceId: any) {
      console.log(invoiceId);
      this.$router.push({
        path: "/invoiceapprov",
        // params: { errors: "error" },
        query: { invoiceId: invoiceId },
      });
    },
    async invoiceToast(data: any) {
      console.log(data.link);
      if (data.link == undefined) {
        const toast = await toastController.create({
          message: data.invoiceID,
          duration: 5000,
          color: data.color,
        });
        return toast.present();
      } else {
        const toast = await toastController.create({
          message: data.invoiceID,
          duration: 5000,
          color: data.color,
          buttons: [
            {
              text: data.report,
              role: "info",
              handler: () => {
                window.open(data.link, "_blank");
              },
            },
          ],
        });
        return toast.present();
      }
    },
  },
});
