
import {
  toastController,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonPage,
  IonButton,
  IonItem,
  IonLabel,
  IonToggle,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { NativeBiometric } from "capacitor-native-biometric";

export default defineComponent({
  name: "HomePage",
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonPage,
    IonButton,
    IonItem,
    IonLabel,
    IonToggle,
  },
  data() {
    return {
      id: "",
      pass: "",
      dashboardData: "",
      name: "",
      biometric: {
        value: false,
      },
      userData: JSON.parse(localStorage.getItem("userData") || "{}"),
    };
  },
  async mounted() {
    const credentials = await NativeBiometric.getCredentials({
      server: "logincred",
    });
    if (credentials.password != "" && credentials.username != "") {
      this.biometric.value = true;
    } else {
      this.biometric.value = false;
    }
    //   this.name = this.$cookies.get("user").user;
    //   if (this.$cookies.get("user").priveleges == "Administrator") {
    //     this.infodashboard = true;
    //   } else {
    //     this.infodashboard = false;
    //   }
  },
  methods: {
    ClearCookies() {
      this.$cookies.remove("user");
      localStorage.removeItem("userData");
      this.$router.replace("/login");
    },
    async OpenToast() {
      const toast = await toastController.create({
        message: "Your settings have been saved.",
        duration: 2000,
      });
      return toast.present();
    },
    BacktoDashboardPage() {
      this.$router.replace("/home/dashboardtab");
    },
    async Biometric() {
        // console.log(this.userData);
      if (this.biometric.value == true) {
        console.log("SetCred");
        NativeBiometric.setCredentials({
          username: this.userData.user,
          password: this.userData.password,
          server: "logincred",
        }).then();
      } else {
        console.log("DelCred");
        NativeBiometric.deleteCredentials({
          server: "logincred",
        }).then();
      }
    },
  },
});
