<template>
  <div
    class="m-3 dark:bg-slate-800 bg-indigo-50 max-w-md mx-auto rounded-xl shadow-md overflow-hidden md:max-w-2xl xl:max-w-3xl"
  >
    <div class="md:flex">
      <div class="mx-auto content-center text-center">
        <p class="error">{{ error }}</p>
        <p class="decode-result">
          Last result: <b>{{ this.checkinout.result }}</b>
        </p>
        <ion-button v-on:click="cameraButton()">{{ camerabutton }}</ion-button>
        <ion-modal
          :is-open="modal"
          :initial-breakpoint="breakpoint"
          handle-behavior="cycle"
          v-on:ion-modal-did-dismiss="cameraButton()"
        >
          <ion-content class="ion-padding">
            <div class="ion-margin-top">
              <center>
                <qrcode-stream
                  v-if="camerashow"
                  :camera="camera"
                  :track="paintOutline"
                  @decode="onDecode"
                  @init="onInit"
                />
              </center>
            </div>
          </ion-content>
        </ion-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { IonButton, toastController } from "@ionic/vue";
import { defineComponent } from "vue";
import { QrcodeStream } from "qrcode-reader-vue3";
import axios from "axios";
export default defineComponent({
  name: "HomePage",
  components: {
    QrcodeStream,
    IonButton,
  },
  data() {
    return {
      id: "",
      pass: "",
      checkinout: {
        result: "",
      },
      responseData: "",
      error: "",
      // timestamp: "",
      camera: "off",
      camerabutton: "Turn On",
      camerashow: false,
      soundurl: "",
      modal: false,
      breakpoint: 0,
    };
  },
  // async mounted() {
  //   this.$socket.$subscribe("attlog", (payload) => {
  //     console.log(payload);
  //   });
    // await axios
    //   .get("https://api.anugerah.me/time")
    //   .then((response) => {
    //     this.timestamp = response.data;
    //   })
    //   .catch((err) => {
    //     this.error = err;
    //   });
  // },
  methods: {
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;
        ctx.strokeStyle = "green";
        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    cameraButton() {
      if (this.$screen.height > 700) {
        this.breakpoint = 1;
      } else {
        this.breakpoint = 0.675;
      }
      if (this.camerabutton == "Turn On") {
        this.camerabutton = "Turn Off";
        this.camera = "auto";
        this.camerashow = true;
        this.modal = true;
      } else {
        this.camerabutton = "Turn On";
        this.camera = "off";
        this.camerashow = false;
        this.modal = false;
      }
    },
    async AttendanceToast() {
      const toast = await toastController.create({
        message: this.responseData.report,
        duration: 3000,
        color: this.responseData.color,
      });
      return toast.present();
    },
    async onDecode(result) {
      let loader = this.$loading.show({
        // Optional parameters
        // container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
      });
      this.checkinout.result = result;
      console.log(this.checkinout);
      await axios
        .post("https://api.anugerah.me/attendance/attendance", this.checkinout)
        .then((response) => {
          this.responseData = response.data;
        })
        .catch((err) => {
          this.error = err;
        });
      this.AttendanceToast();
      loader.hide();
      this.attSound();
    },
    attSound() {
      this.soundurl = "https://apps.anugerah.me/sound/attnotif.mp3";
      var audio = new Audio(this.soundurl);
      audio.play();
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
  },
});
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}
</style>
