
import {
  toastController,
  IonButton,
  IonLabel,
  IonInput,
  IonItem,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default defineComponent({
  name: "HomePage",
  components: {
    IonButton,
    IonLabel,
    IonInput,
    IonItem,
    IonSelect,
    IonSelectOption,
    Line,
  },
  async mounted() {
    this.$socket.$subscribe("slider", (payload: never) => {
      this.chatmessage.push(payload);
      console.log(this.chatmessage);
      this.sliderOriginData = payload;
    });
    this.$socket.$subscribe("lamp", (payload: never) => {
      this.chatmessage.push(payload);
      console.log(this.chatmessage);
      this.lampOriginData = payload;
    });
    // axios
    // .get("https://api.anugerah.me/dashboard")
    // .then((response) => {
    //   this.infodata = response.data;
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
    // axios
    // .get("https://api.anugerah.me/dashboard")
    // .then((response) => {
    //   this.infodata = response.data;
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  },
  data() {
    return {
      sliderData: {
        d: {
          Val1: 0,
        },
        ts: "2023-01-09T20:40:26.419203",
      },
      lampData: {
        d: {
          Lamp1: "",
        },
        ts: "2023-02-20T14:59:19.416554",
      },
      sliderOriginData: {
        message: {
          d: {
            Val1: 0,
          },
          ts: "2023-02-20T14:59:19.416554",
        },
      },
      lampOriginData: {
        message: {
          d: {
            Lamp1: "",
          },
          ts: "2023-02-20T14:59:19.416554",
        },
      },
      chatmessage: [],
      chartData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [40, 39, 10, 40, 39, 80, 40],
          },
          {
            label: "Data Two",
            backgroundColor: "#f10000",
            data: [0, 29, 10, 10, 29, 40, 20],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "Chart.js Line Chart - Cubic interpolation mode",
          },
        },
      },
    };
  },
  methods: {
    async SendSliderSocketData() {
      this.$socket.client.emit("slider", this.sliderData);
    },
    async SendLampSocketData() {
      this.$socket.client.emit("lamp", this.lampData);
    },
    async OpenToast() {
      const toast = await toastController.create({
        message: "Your settings have been saved.",
        duration: 2000,
      });
      return toast.present();
    },
  },
});
