import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "ion-margin-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_qrcode_vue = _resolveComponent("qrcode-vue")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "attendance",
                href: "/home/attendance"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.calendar }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label)
                ]),
                _: 1
              }),
              (_ctx.tabDisable)
                ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                    key: 0,
                    tab: "stock",
                    href: "/home/stock"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.archive,
                        class: ""
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_tab_button, {
                tab: "dashboardtab",
                href: "/home/dashboardtab"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.layers }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label)
                ]),
                _: 1
              }),
              (_ctx.tabDisable)
                ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                    key: 1,
                    tab: "admin",
                    href: "/home/admin"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.menu }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              false
                ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                    key: 2,
                    tab: "chat",
                    class: "bg-slate-100",
                    href: "/home/chat"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.chatbubbleEllipses }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Chat(Beta)")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_tab_button, {
                tab: "about",
                href: "/home/about"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.alertCircle }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, {
        "is-open": "false",
        "initial-breakpoint": 0.5,
        breakpoints: [0, 0.25, 0.5, 0.75],
        "handle-behavior": "cycle"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_content, { class: "ion-padding" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Click the handle above to advance to the next breakpoint.")
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_component_qrcode_vue, {
                class: "mx-auto m-4 border-8 border-white",
                size: "250",
                level: "L",
                git: "",
                value: _ctx.qrcode
              }, null, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}