
import {
  toastController,
  IonButton,
  IonLabel,
  IonItem,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
} from "@ionic/vue";
import { defineComponent } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import axios from "axios";

export default defineComponent({
  name: "HomePage",
  components: {
    IonButton,
    IonLabel,
    IonItem,
    IonTextarea,
    IonSelect,
    IonSelectOption,
    Datepicker,
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
  },
  async mounted() {
    let loader = this.$loading.show({
      // Optional parameters
      // container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: false,
    });
    await axios
      .post("https://api.anugerah.me/attendance/updateatt", this.attendanceList)
      .then((response) => {
        // console.log(response.data);
        this.attendanceList = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    loader.hide();
  },
  data() {
    return {
      attendanceList: {
        username: "",
        id: "",
      },
      userData: "",
      selectedOption: {
        reason: "",
        date: "",
        remark: "",
      },
      toast: {
        color: "",
        message: "",
      },
      upload: {
        photo: "",
      },
    };
  },

  methods: {
    async submitAttendanceModification() {
      let loader = this.$loading.show({
        // Optional parameters
        // container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
      });

      let modifiedUserData = [this.userData, this.selectedOption];
      await axios
        .post("https://api.anugerah.me/attendance/updateatt", modifiedUserData)
        .then((response) => {
          // console.log(response.data);
          this.toast = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      loader.hide();
      this.ModifiedToast();
    },
    ClearCookies() {
      this.$cookies.remove("attendanceList");
      this.$router.replace("/login");
      localStorage.removeItem("userData");
    },
    home() {
      this.$router.push("/login");
    },
    async ModifiedToast() {
      const toast = await toastController.create({
        message: this.toast.message,
        duration: 2000,
        color: this.toast.color,
      });
      return toast.present();
    },
    ///// NEW LINE FOR UPLOAD PHOTO
    uploadImage(event: any) {
      let tests = event.target.files[0];
      this.createBase64(tests);
    },
    createBase64(data: any) {
      const reader = new FileReader();

      reader.onload = (e) => {
        let datas = e.target?.result as string;
        // this.upload.photo = datas.substring(datas.indexOf(",") + 1);
        this.upload.photo = datas;
        console.log(this.upload.photo);
        this.fileAxios();
      };
      reader.readAsArrayBuffer(data);
    },
    async fileAxios() {
      await axios
        .post("https://api.anugerah.me/attendance/uploadimage", this.upload)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    BacktoDashboardPage() {
      this.$router.replace("/home/admin");
    },
    ////
  },
});
