
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonPage,
  IonButton,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "HomePage",
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonPage,
    IonButton,
    IonRefresher,
    IonRefresherContent,
  },
  data() {
    return {
      invoiceList: "",
      id: "",
      pass: "",
      selectedFilter: {
        mode: "",
        date: "",
      },
      test: "",
    };
  },
  async mounted() {
    let loader = this.$loading.show({
      // Optional parameters
      // container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: false,
    });
    this.$socket.$subscribe("invupdate", (payload: never) => {
      console.log(payload);
      this.invLogsUpdate();
    });
    this.invLogsUpdate();
    loader.hide();
  },
  methods: {
    async invLogsUpdate() {
      await axios
        .get("https://api.anugerah.me/invoice/invoicelogs")
        .then((response) => {
          console.log(response.data);
          this.invoiceList = response.data;
          console.log(this.invoiceList.length);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async ContentRefresh(event: any) {
      await axios
        .get("https://api.anugerah.me/invoice/invoicelogs")
        .then((response) => {
          console.log(response.data);
          this.invoiceList = response.data;
          console.log(this.invoiceList.length);
        })
        .catch((error) => {
          console.log(error);
        });
      event.target.complete();
    },
    BacktoDashboardPage() {
      this.$router.replace("/home/dashboardtab");
    },
    // async sendDataFilter() {
    //   let loader = this.$loading.show({
    //     // Optional parameters
    //     // container: this.fullPage ? null : this.$refs.formContainer,
    //     canCancel: false,
    //   });
    //   this.attlist = "";
    //   const senddata = this.selectedFilter;
    //   await axios
    //     .post(
    //       "https://api.anugerah.me/attendance/filterattendancelogs",
    //       senddata
    //     )
    //     .then((response) => {
    //       console.log(response.data);
    //       this.attlist = response.data;
    //       console.log(this.attlist.length);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    //   loader.hide();
    // },
    invoiceEditPage(invoiceId: any) {
      console.log(invoiceId);
      this.$router.push({
        path: "/invoiceapprov",
        // params: { errors: "error" },
        query: { invoiceId: invoiceId },
      });
    },
  },
});
