
import {
  toastController,
  IonButton,
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonInput,
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "HomePage",
  components: {
    IonButton,
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonInput,
  },
  async mounted() {
    let loader = this.$loading.show({
      // Optional parameters
      // container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: false,
    });
    await axios
      .get("https://api.anugerah.me/stock/stocklists")
      .then((response) => {
        console.log(response.data);
        this.stockList = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    loader.hide();
  },
  data() {
    return {
      stockList: {},
      userData: "",
      selectedOption: {
        reason: "",
        date: "",
        remark: "",
      },
      toast: {
        color: "",
        message: "",
      },
      editStock: {
        stockId: "",
        quantity: "",
      },
    };
  },

  methods: {
    async submitStockModifier(stockId: any, quantity: any) {
      let loader = this.$loading.show({
        // Optional parameters
        // container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
      });
      this.editStock.stockId = stockId;
      this.editStock.quantity = quantity;
      await axios
        .post("https://api.anugerah.me/stock/editstock", this.editStock)
        .then((response) => {
          // console.log(response.data);
          this.toast = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      loader.hide();
      this.ModifiedToast();
    },
    ClearCookies() {
      this.$cookies.remove("attendanceList");
      this.$router.replace("/login");
      localStorage.removeItem("userData");
    },
    home() {
      this.$router.push("/login");
    },
    async ModifiedToast() {
      const toast = await toastController.create({
        message: this.toast.message,
        duration: 2000,
        color: this.toast.color,
      });
      return toast.present();
    },
    BacktoDashboardPage() {
      this.$router.replace("/home/admin");
    },
    ////
  },
});
