
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonPage,
  IonButton,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";
import Datepicker from "@vuepic/vue-datepicker";

export default defineComponent({
  name: "HomePage",
  components: {
    Datepicker,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonPage,
    IonButton,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
  },
  data() {
    return {
      attlist: "",
      id: "",
      pass: "",
      selectedFilter: {
        mode: "",
        date: "",
      },
      test: "",
    };
  },
  async mounted() {
    let loader = this.$loading.show({
      // Optional parameters
      // container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: false,
    });
    await axios
      .get("https://api.anugerah.me/attendance/attendancelogs")
      .then((response) => {
        console.log(response.data);
        this.attlist = response.data;
        console.log(this.attlist.length);
      })
      .catch((error) => {
        console.log(error);
      });
    loader.hide();
  },
  methods: {
    BacktoDashboardPage() {
      this.$router.replace("/home/dashboardtab");
    },
    async sendDataFilter() {
      let loader = this.$loading.show({
        // Optional parameters
        // container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
      });
      this.attlist = "";
      const senddata = this.selectedFilter;
      await axios
        .post(
          "https://api.anugerah.me/attendance/filterattendancelogs",
          senddata
        )
        .then((response) => {
          console.log(response.data);
          this.attlist = response.data;
          console.log(this.attlist.length);
        })
        .catch((error) => {
          console.log(error);
        });
      loader.hide();
    },
  },
});
