import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import LoginPage from "../views/LoginPage.vue";
import HomePage from "../views/HomePage.vue";
import StockLogsPage from "../views/StockLogsPage.vue";
import AttendanceLogsPage from "../views/AttendanceComponent/AttendanceLogsPage.vue";
import AttendanceScanner from "../views/AttendanceComponent/AttendanceStation.vue";
import InvoicePage from "../views/InvoiceComponent/InvoicePage.vue";
import InvoiceApproval from "../views/InvoiceComponent/InvoiceApproval.vue";
import InvoiceMaker from "../views/InvoiceComponent/InvoiceMaker.vue";
import AddHoliday from "../views/AdminComponent/AdminAddHoliday.vue";
import EditAttendance from "../views/AdminComponent/AdminEditAttendance.vue";
import StockEditor from "../views/AdminComponent/AdminStockEditor.vue";
import Setting from "../views/SettingPage.vue";
import Refress from "../views/RefrTestamen.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/stocklogs",
    name: "StockLog",
    component: StockLogsPage,
  },
  {
    path: "/attscanner",
    name: "AttendanceScanner",
    component: AttendanceScanner,
  },
  {
    path: "/attlogs",
    name: "AttendanceLogs",
    component: AttendanceLogsPage,
  },
  {
    path: "/invoice",
    name: "Invoice",
    component: InvoicePage,
  },
  {
    path: "/invoiceapprov",
    name: "InvoiceApproval",
    component: InvoiceApproval,
  },
  {
    path: "/invoicemaker",
    name: "InvoiceMaker",
    component: InvoiceMaker,
  },
  {
    path: "/addholiday",
    name: "AddHoliday",
    component: AddHoliday,
  },
  {
    path: "/editattendance",
    name: "EditAttendance",
    component: EditAttendance,
  },
  {
    path: "/stockeditor",
    name: "StockEditor",
    component: StockEditor,
  },
  {
    path: "/setting",
    name: "Setting",
    component: Setting,
  },
  {
    path: "/ref",
    name: "Ref",
    component: Refress,
  },
  {
    path: "/home/",
    name: "Home",
    component: HomePage,
    children: [
      {
        path: "attendance",
        component: () => import("@/views/AttendancePage.vue"),
      },
      {
        path: "stock",
        component: () => import("@/views/StockPage.vue"),
      },
      {
        path: "dashboardtab",
        component: () => import("@/views/DashboardPage.vue"),
      },
      {
        path: "admin",
        component: () => import("@/views/AdminPage.vue"),
      },
      {
        path: "chat",
        component: () => import("@/views/ChatPage.vue"),
      },
      {
        path: "about",
        component: () => import("@/views/AboutPage.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
