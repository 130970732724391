
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import { SafeArea } from "capacitor-plugin-safe-area";
import { Device } from "@capacitor/device";
// import { create } from "ionicons/icons";

const __default__ = defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  data() {
    return {
      top: 0,
      bot: 0,
      toppx: "",
      botpx: "",
    };
  },
  async create() {
    this.SafeArea();
  },
  methods: {
    async SafeArea() {
      if ((await Device.getInfo()).platform == "ios") {
        await SafeArea.getSafeAreaInsets().then(({ insets }) => {
          console.log(insets.top);
          console.log(insets.bottom);
          this.top = insets.top;
          this.toppx = this.top + "px";
          this.bot = insets.bottom;
          this.botpx = this.bot + "px";
        });
      } else {
        this.top = 0;
        this.toppx = this.top + "px";
        this.bot = 0;
        this.botpx = this.bot + "px";
      }
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "abc36ae8": (_ctx.toppx),
  "adbe9b04": (_ctx.botpx)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__