
import {
  toastController,
  IonContent,
  IonInput,
  IonPage,
  IonItem,
  IonLabel,
  IonButton,
  alertController,
  useBackButton,
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";
import shortuid from "short-uuid";
import { Device } from "@capacitor/device";
import { AppVersion } from "@awesome-cordova-plugins/app-version";
import { Browser } from "@capacitor/browser";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { App } from "@capacitor/app";
import { NativeBiometric } from "capacitor-native-biometric";
import { SafeArea } from "capacitor-plugin-safe-area";
// import { Geolocation } from "@capacitor/geolocation";

const __default__ = defineComponent({
  name: "HomePage",
  components: {
    IonContent,
    IonInput,
    IonPage,
    IonItem,
    IonLabel,
    IonButton,
  },
  data() {
    return {
      enteredData: {
        username: "",
        password: "",
        token: "",
        permission: true,
        deviceInfo: "",
        appversion: "",
      },
      responseData: {
        validation: false,
        report: "",
        user: "",
        priveleges: "",
        color: "",
        token: "",
      },
      cookiesData: {
        user: "",
        password: "",
        priveleges: "",
        validation: false,
        token: "",
        appversion: "",
      },
      hidenshowpass: {
        text: "show",
        type: "password",
      },
      loginstat: "primary",
      error: "",
      curAppInfo: {
        appVersion: "",
      },
      getAppInfo: {
        appVersion: "",
      },
      alertText: {
        header: "",
        subHeader: "",
        message: "",
        buttonText: "",
      },
      top: 0,
      px: "",
      location: {
        lat: 0,
        lon: 0,
      },
    };
  },
  // setup() {
  //   // const ionRouter = useIonRouter();
  //   useBackButton(-1, () => {
  //   //   if (!ionRouter.canGoBack()) {
  //   //     App.exitApp();
  //   //   }
  //     App.exitApp();
  //   });
  // },
  async mounted() {
    // const location = await Geolocation.getCurrentPosition();
    // this.location.lat = location.coords.latitude;
    // this.location.lon = location.coords.longitude;
    this.checkInternetConnection();
    await SafeArea.getSafeAreaInsets().then(({ insets }) => {
      console.log(insets);
      this.top = insets.top;
      this.px = this.top + "px";
      console.log(this.px);
    });
    useBackButton(-1, () => {
      //   if (!ionRouter.canGoBack()) {
      //     App.exitApp();
      //   }
      App.exitApp();
    });

    this.enteredData.deviceInfo = (await Device.getId()).uuid;
    this.curAppInfo.appVersion = await AppVersion.getVersionNumber();

    await axios
      .get("https://api.anugerah.me/login/appversion")
      .then((response) => {
        this.getAppInfo = response.data;
        console.log(response.data);
      })
      .catch((err) => {
        this.error = err;
        this.getAppInfo.appVersion = err.message;
      });
    /// IF NO INTERNET WHAT WILL HAPPEN TO THIS SECTION (NEED TO FIX AND CLEAN IT)
    if ((await Device.getInfo()).platform != "web") {
      if (this.getAppInfo.appVersion.includes("Network") == true) {
        this.alertText.header = "Server 404";
        this.alertText.subHeader = "Service not Available";
        this.alertText.message =
          "Server is on Maintainance, Please try again later";
        this.alertText.buttonText = "Ok";
        this.newAppVersionAlert();
      } else if (this.curAppInfo.appVersion != this.getAppInfo.appVersion) {
        this.alertText.header = "New Update Available";
        this.alertText.subHeader =
          "Please Update to " + this.getAppInfo.appVersion;
        this.alertText.message =
          "Current App Version is " + this.curAppInfo.appVersion;
        this.alertText.buttonText = "Update";
        this.newAppVersionAlert();
      }
      console.log(this.getAppInfo.appVersion.includes("Network"));
      console.log(this.getAppInfo.appVersion);
      console.log(this.curAppInfo.appVersion);
      // this.SendMessage();
    }
  },
  // beforeUnmount(){
  //   console.log("test");
  // },
  // unmounted(){
  //   console.log("test1");
  // },
  // sockets: {
  //   connect: function () {
  //     console.log("socket to notification channel connected");
  //   },
  // },
  methods: {
    // LoginClicked() {
    //   if (this.enteredData.username == "" || this.enteredData.password == "") {
    //     this.failedToast();
    //     console.log("failed");
    //   } else {
    //     this.SubmitLoginData();
    //   }
    // },

    checkInternetConnection() {
      if (navigator.onLine) {
        this.responseData.report = "Connected to the Internet";
        this.responseData.color = "success";
        this.internetToast();
      } else {
        this.responseData.report = "Internet are not Available";
        this.responseData.color = "warning";
        this.internetToast();
      }
    },
    hideShowPassword() {
      if (
        this.hidenshowpass.text == "show" &&
        this.hidenshowpass.type == "password"
      ) {
        this.hidenshowpass.text = "hide";
        this.hidenshowpass.type = "text";
      } else {
        this.hidenshowpass.text = "show";
        this.hidenshowpass.type = "password";
      }
    },
    async newAppVersionAlert() {
      const alert = await alertController.create({
        header: this.alertText.header,
        subHeader: this.alertText.subHeader,
        message: this.alertText.message,
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              console.log("cancel");
              App.exitApp();
            },
          },
          {
            text: this.alertText.buttonText,
            role: "confirm",
            handler: () => {
              console.log("confirm");
              if (this.alertText.buttonText != "Ok") {
                this.downloadNewAppVersion();
                App.exitApp();
              }
            },
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log(`Dismissed with role: ${role}`);
    },
    async downloadNewAppVersion() {
      await Browser.open({
        url:
          "https://apps.anugerah.me/apk/stable/AnugerahApp V" +
          this.getAppInfo.appVersion +
          ".apk",
      });
    },
    async submitLoginData() {
      let loader = this.$loading.show({
        // Optional parameters
        // container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
      });
      this.enteredData.token = shortuid().new();
      this.enteredData.appversion = this.curAppInfo.appVersion;
      this.loginstat = "warning";
      // const loginData = JSON.stringify(this.enteredData);
      // console.log(loginData);
      await axios
        .post("https://api.anugerah.me/login/loginvalidation", this.enteredData)
        .then(async (response) => {
          this.responseData = response.data;
          if ((await Device.getInfo()).platform != "web") {
            this.cookiesData.password = this.enteredData.password;
          }
          this.cookiesData.priveleges = response.data.priveleges;
          this.cookiesData.user = response.data.user;
          this.cookiesData.validation = response.data.validation;
          this.cookiesData.token = response.data.token;
          // console.log(this.responseData);
        })
        .catch((err) => {
          this.responseData.report = err.message;
          this.responseData.validation = false;
          this.responseData.color = "medium";
          this.error = err;
        });
      loader.hide();
      if (this.responseData.validation == false) {
        this.loginstat = "primary";
        this.hapticsVibration();
        this.loginToast();
        console.log("Failed");
      } else {
        this.loginstat = "primary";
        this.$cookies.set("user", this.cookiesData);
        this.loginToast();
        console.log("Success");
        // console.log(this.cookiesData);/
        localStorage.userData = JSON.stringify(this.cookiesData);
        this.$router.replace({
          path: "/home/dashboardtab",
          query: { username: this.enteredData.username },
        });
      }
    },
    async loginToast() {
      const toast = await toastController.create({
        message: this.responseData.report,
        duration: 1500,
        color: this.responseData.color,
        animated: true,
        position: "top",
      });
      return toast.present();
    },
    async internetToast() {
      const toast = await toastController.create({
        message: this.responseData.report,
        duration: 3000,
        color: this.responseData.color,
        animated: true,
        position: "top",
      });
      return toast.present();
    },
    async biometricToast() {
      const toast = await toastController.create({
        message: "You havent Register Biometric",
        duration: 1500,
        color: "danger",
        animated: true,
        position: "top",
      });
      return toast.present();
    },
    async hapticsVibration() {
      await Haptics.impact({ style: ImpactStyle.Heavy });
    },
    // ADD BIOMETRIC
    async performBiometricVerificatin() {
      const credentials = await NativeBiometric.getCredentials({
        server: "logincred",
      });
      if (credentials.password == undefined && credentials.username == undefined) {
        this.biometricToast;
      } else {
        const result = await NativeBiometric.isAvailable();

        if (!result.isAvailable) return;

        // const isFaceID = result.biometryType == BiometryType.FACE_ID;

        const verified = await NativeBiometric.verifyIdentity({
          // reason: "For easy log in",
          title: "Log in",
          // subtitle: "Maybe add subtitle here?",
          // description: "Maybe a description too?",
        })
          .then(() => true)
          .catch(() => false);

        if (!verified) return;

        this.enteredData.username = credentials.username;
        this.enteredData.password = credentials.password;
        this.submitLoginData();
      }
    },
    // async setCred() {
    //   NativeBiometric.setCredentials({
    //     username: "Wendrian",
    //     password: "W3ndr14n",
    //     server: "api.anugerah.me",
    //   }).then();
    // },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "015791aa": (_ctx.px)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__