
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonPage,
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  toastController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "HomePage",
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonPage,
    IonButton,
    IonInput,
    IonItem,
    IonLabel,
  },
  data() {
    return {
      detail: {
        issuer: "",
        companyname: "",
      },
      productType: [
        {
          id: 0,
          description: "",
          quantity: "",
          price: "",
        },
      ],
      productDetail: {
        issuer: "",
        customer: "",
        invId: "",
      },
      invoiceFinal: {
        invDetail: [
          {
            customer: "",
          },
        ],
        invIssuer: "",
        invProduct: {},
        invID: "",
      },
      type: "0",
      type2: "0",
      last: "1",
      render: false,
    };
  },
  async mounted() {
    let loader = this.$loading.show({
      // Optional parameters
      // container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: false,
    });
    // await axios
    //   .get("https://api.anugerah.me/invoice/invoicelogs")
    //   .then((response) => {
    //     console.log(response.data);
    //     this.invoiceList = response.data;
    //     console.log(this.invoiceList.length);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    loader.hide();
  },
  methods: {
    Render() {
    //   console.log(this.type);
      this.type2 = this.type;
    //   console.log(this.last);
      this.render = false;
      for (let x = 0; x < parseInt(this.last); x++) {
        this.productType.splice(x);
      }
      console.log(this.productType);
      if (parseInt(this.type) > 0) {
        for (let x = 0; x < parseInt(this.type); x++) {
          this.productType.push({
            id: x,
            description: "",
            quantity: "",
            price: "",
          });
        }
      }
      this.last = this.type;
    //   console.log(this.productType);
      this.render = true;
    },
    BacktoDashboardPage() {
      this.$router.replace("/home/dashboardtab");
    },
    async invoiceToast(data: any) {
      const toast = await toastController.create({
        message: data.invoiceID,
        duration: 5000,
        color: data.color,
        buttons: [
          {
            text: data.report,
            role: "info",
            handler: () => {
              window.open(data.link, "_blank");
            },
          },
        ],
      });
      return toast.present();
    },
    async ApproveItem() {
      let loader = this.$loading.show({
        // Optional parameters
        // container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
      });
      this.invoiceFinal.invProduct = this.productType;
      this.invoiceFinal.invDetail[0].customer = this.productDetail.customer;
      this.invoiceFinal.invIssuer = this.productDetail.issuer;
      this.invoiceFinal.invID = this.productDetail.invId;
      this;
      await axios
        .post(
          "https://api.anugerah.me/invoice/invoiceapproval",
          this.invoiceFinal
        )
        .then((response) => {
          //   console.log(response.data);
          this.invoiceToast(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      //   console.log(this.invoiceFinal);
      loader.hide();
    },
  },
  // async sendDataFilter() {
  //   let loader = this.$loading.show({
  //     // Optional parameters
  //     // container: this.fullPage ? null : this.$refs.formContainer,
  //     canCancel: false,
  //   });
  //   this.attlist = "";
  //   const senddata = this.selectedFilter;
  //   await axios
  //     .post(
  //       "https://api.anugerah.me/attendance/filterattendancelogs",
  //       senddata
  //     )
  //     .then((response) => {
  //       console.log(response.data);
  //       this.attlist = response.data;
  //       console.log(this.attlist.length);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   loader.hide();
  // },
});
