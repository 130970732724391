
import {
  toastController,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonPage,
  IonButton,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";
import Datepicker from "@vuepic/vue-datepicker";

export default defineComponent({
  name: "HomePage",
  components: {
    Datepicker,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonPage,
    IonButton,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
  },
  data() {
    return {
      stocklist: "",
      id: "",
      pass: "",
      selectedFilter: {
        mode: "",
        date: "",
      },
      test: "",
    };
  },
  async mounted() {
    let loader = this.$loading.show({
      // Optional parameters
      // container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: false,
    });
    await axios
      .get("https://api.anugerah.me/stock/stocklogs")
      .then((response) => {
        console.log(response.data);
        this.stocklist = response.data;
        console.log(this.stocklist.length);
      })
      .catch((error) => {
        console.log(error);
      });
    loader.hide();
  },
  methods: {
    BacktoStockPage() {
      this.$router.replace("/home/dashboardtab");
    },
    async OpenToast() {
      const toast = await toastController.create({
        message: "Your settings have been saved.",
        duration: 2000,
      });
      return toast.present();
    },
    async sendDataFilter() {
      let loader = this.$loading.show({
        // Optional parameters
        // container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
      });
      this.stocklist = "";
      const senddata = this.selectedFilter;
      await axios
        .post("https://api.anugerah.me/stock/filterstocklogs", senddata)
        .then((response) => {
          console.log(response.data);
          this.stocklist = response.data;
          console.log(this.stocklist.length);
        })
        .catch((error) => {
          console.log(error);
        });
      loader.hide();
    },
  },
});
