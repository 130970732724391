
import {
  toastController,
  IonButton,
  IonDatetime,
  IonPage,
  IonContent,
  IonToolbar,
  IonHeader,
  IonTitle,
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "HomePage",
  components: {
    IonButton,
    IonDatetime,
    IonPage,
    IonToolbar,
    IonContent,
    IonHeader,
    IonTitle,
  },
  setup() {
    const isWeekday = (dateString: string) => {
      const date = new Date(dateString);
      const utcDay = date.getUTCDay();

      /**
       * Date will be enabled if it is not
       * Sunday or Saturday
       */
      return utcDay !== 0; //&& utcDay !== 6
    };

    return { isWeekday };
  },
  async mounted() {
    // let loader = this.$loading.show({
    //   // Optional parameters
    //   // container: this.fullPage ? null : this.$refs.formContainer,
    //   canCancel: false,
    // });
    await axios
      .get("https://api.anugerah.me/admin/getholidaydata")
      .then((response) => {
        console.log(response.data);
        this.holidayDate = response.data;
        this.showDate();
      })
      .catch((error) => {
        console.log(error);
      });
    // loader.hide();
    // Set Timeout
    // setTimeout(() => {
    //     this.show = true;
    //   }, 1000);
  },
  data() {
    return {
      holidayDate: "",
      toast: {
        color: "",
        message: "",
      },
      show: false,
    };
  },

  methods: {
    // async submitAttendanceModification() {
    //   let loader = this.$loading.show({
    //     // Optional parameters
    // container: this.fullPage ? null : this.$refs.formContainer,
    //   canCancel: false,
    // });

    // let modifiedUserData = [this.userData, this.selectedOption];
    // await axios
    //   .post("https://api.anugerah.me/attendance/updateatt", modifiedUserData)
    //   .then((response) => {
    //     console.log(response.data);
    //     this.toast = response.data;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // loader.hide();
    // this.ModifiedToast();
    // },
    async submitHolidayModification() {
      let loader = this.$loading.show({
        // Optional parameters
        // container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
      });
      await axios
        .post("https://api.anugerah.me/admin/postholidaydata", this.holidayDate)
        .then((response) => {
          console.log(response.data);
          this.toast = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      loader.hide();
      this.ModifiedToast();
    },
    async ModifiedToast() {
      const toast = await toastController.create({
        message: this.toast.message,
        duration: 2000,
        color: this.toast.color,
      });
      return toast.present();
    },
    showDate() {
      this.show = true;
    },
    BacktoDashboardPage() {
      this.$router.replace("/home/admin");
    },
  },
});
