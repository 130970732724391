
  import {
    toastController,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonPage,
    IonButton,
  } from "@ionic/vue";
  import { defineComponent } from "vue";
  import LiveChat from "./ChatComponent/LiveChat.vue";
  
  export default defineComponent({
    name: "HomePage",
    components: {
      IonContent,
      IonHeader,
      IonTitle,
      IonToolbar,
      IonPage,
      IonButton,
      livechat: LiveChat,
    },
    data() {
      return {
        id: "",
        pass: "",
        dashboardData: "",
        name: "",
        // infodashboard: false,
      };
    },
    mounted() {
    //   this.name = this.$cookies.get("user").user;
    //   if (this.$cookies.get("user").priveleges == "Administrator") {
    //     this.infodashboard = true;
    //   } else {
    //     this.infodashboard = false;
    //   }
    },
    methods: {
      ClearCookies() {
        this.$cookies.remove("user");
        localStorage.removeItem('userData');
        this.$router.replace("/login");
      },
      async OpenToast() {
        const toast = await toastController.create({
          message: "Your settings have been saved.",
          duration: 2000,
        });
        return toast.present();
      },
    },
  });
  