
import { defineComponent } from "vue";
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  useBackButton,
  useIonRouter,
  IonModal,
} from "@ionic/vue";
import {
  archive,
  menu,
  calendar,
  apps,
  layers,
  alertCircle,
  chatbubbleEllipses,
} from "ionicons/icons";
import { App } from "@capacitor/app";
import QrcodeVue from "qrcode.vue";

export default defineComponent({
  name: "TabsPage",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    IonModal,
    QrcodeVue,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData") || "{}"),
      tabDisable: true,
      username: this.$route.query.username,
      qrcode: "",
    };
  },
  setup() {
    return {
      archive,
      menu,
      calendar,
      apps,
      layers,
      alertCircle,
      chatbubbleEllipses,
    };
  },
  mounted() {
    const ionRouter = useIonRouter();
    useBackButton(-1, () => {
      if (!ionRouter.canGoBack()) {
        App.exitApp();
      }
      // App.exitApp();
    });
    this.attDisable();
    console.log(this.userData.priveleges);
  },
  methods: {
    // attendanceTab() {
    //   this.$router.push({
    //     path: "/home/attendance",
    //     query: { username: this.username },
    //   });
    // },
    // stockTab() {
    //   this.$router.push({
    //     path: "/home/stock",
    //     query: { username: this.username },
    //   });
    // },
    // adminTab() {
    //   this.$router.push({
    //     path: "/home/admin",
    //     query: { username: this.username },
    //   });
    // },
    // dashboardTab() {
    //   this.$router.push({
    //     path: "/home/dashboardtab",
    //     query: { username: this.username },
    //   });
    // },
    attDisable() {
      if (
        this.userData.priveleges === "Driver" ||
        this.userData.priveleges === "Attendance"
      ) {
        this.tabDisable = false;
      } else {
        this.tabDisable = true;
      }
    },
  },
});
